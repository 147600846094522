import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

export default function Banner() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/building-reflection-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="feature-banner">
      <Image
        className="feature-banner__bg"
        fluid={data.file.childImageSharp.fluid}
        alt="Glass on building"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <h1>Window Tinting Wellington</h1>
            <p>
              For decades Window Magic has been the leading name in safe and
              affordable window tinting within the Wellington region.
            </p>
            <a href="/contact/" className="btn btn-lg btn-warning">
              Free Quote
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
