import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "../styles/main.scss"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import Image from "gatsby-image"

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      about: file(
        relativePath: { eq: "images/IMG_2624psnew-e1594779052974.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      residential: file(relativePath: { eq: "images/house-3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      commercial: file(
        relativePath: { eq: "images/Gold-film-shop-front.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      automotive: file(relativePath: { eq: "images/DSCF2099.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Window Magic | Quality & affordable window tinting</title>
        <meta
          name="description"
          content="For decades Window Magic has been the leading name in safe and affordable window tinting within the Wellington region."
        />
      </Helmet>

      <Layout hidePageHeader={true}>
        <Banner />

        <section className="page-section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <h2 className="section-heading">About Us</h2>
                <p>
                  Window Magic has been the leading name in Window Tinting
                  within the Wellington area for decades. Our goal is to present
                  a finished product that not only looks great but is safe and
                  affordable for you.
                </p>
                <p>
                  Our attention to detail not only shines in our quality
                  workmanship but we also hand source all of our product
                  ourselves, which allows us to have some of the most
                  competitive pricing on the market.
                </p>
                <p>
                  Trust in our years’ successful partnerships with some of the
                  top dealerships in the city. Cars, buildings, trucks, boats,
                  houses, celebrity vehicles, we really have done it all.
                </p>
              </div>
              <div className="col-lg-5 d-flex flex-column justify-content-center">
                <div className="main-content__gallery mb-2 w-100 ">
                  <Image
                    fluid={{
                      ...data.about.childImageSharp.fluid,
                      aspectRatio: 4 / 3,
                    }}
                    alt="Greg with Window Magic car"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-light">
          <div className="container">
            <h2 className="section-heading">Services</h2>
            <div className="row">
              <div className="mx-auto mb-gutter col-sm-6 col-lg-4">
                <Link to="/residential" className="d-block hover-overlay">
                  <Image
                    fluid={{
                      ...data.residential.childImageSharp.fluid,
                      aspectRatio: 4 / 3,
                    }}
                    alt="Ranch slider tinting"
                  />
                </Link>
                <div className="p-3 bg-white">
                  <h5>Residential</h5>
                  <p data-mh="service-description">
                    Window tinting your residential property is a great way to
                    provide safety and security to your family, pets and guests.
                  </p>
                  <Link to="/residential" className="btn btn-danger">
                    Read more
                  </Link>
                </div>
              </div>
              <div className="mx-auto mb-gutter col-sm-6 col-lg-4">
                <Link to="/commercial" className="d-block hover-overlay">
                  <Image
                    fluid={{
                      ...data.commercial.childImageSharp.fluid,
                      aspectRatio: 4 / 3,
                    }}
                    alt="Shop front tinting"
                  />
                </Link>
                <div className="p-3 bg-white">
                  <h5>Commercial</h5>
                  <p data-mh="service-description">
                    We have a great range of products for your office, such as
                    your company brand motifs for internal glass walls.
                  </p>
                  <Link to="/commercial" className="btn btn-danger">
                    Read more
                  </Link>
                </div>
              </div>
              <div className="mx-auto mb-gutter col-sm-6 col-lg-4">
                <Link to="/automotive" className="d-block hover-overlay">
                  <Image
                    fluid={{
                      ...data.automotive.childImageSharp.fluid,
                      aspectRatio: 4 / 3,
                    }}
                    alt="Vehicle tinting"
                  />
                </Link>
                <div className="p-3 bg-white">
                  <h5>Automotive</h5>
                  <p data-mh="service-description">
                    Tinting vehicles provides benefits like protection and
                    privacy. All of our tints come with a full manufacturer
                    warranty.
                  </p>
                  <Link to="/automotive" className="btn btn-danger">
                    Read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
